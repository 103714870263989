<template>
  <div>
    <v-row
      v-if="addEmployee"
      class="white--text contactForm mt-16 pt-6"
      justify="center"
    >
      <v-col
        cols="12"
        md="6"
        xl="4"
        class="d-flex flex-column justify-center my-auto"
        :class="$vuetify.breakpoint.smAndDown ? 'pb-16 px-3' : 'px-16'"
      >
        <v-btn text @click="startAdd()" color="white" class="mx-auto">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
        <v-form v-model="valid">
          <v-card
            width="100%"
            height="300"
            @click="onPickFile()"
            class="mb-8 d-flex justify-center align-center"
            id="imgCard"
            dark
            flat
            :color="!isImage ? '' : 'transparent'"
          >
            <div v-if="!isImage">
              <v-icon size="60"> mdi-image </v-icon>
            </div>
            <v-img
              v-else
              height="300"
              contain
              :src="!isImage ? '' : employee.image.temp"
            >
            </v-img>
          </v-card>
          <input
            type="file"
            style="display: none"
            ref="image"
            accept="image/*"
            @change="onFilePicked"
          />
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="employee.name"
                label="Employee Name"
                hide-details="auto"
                outlined
                dark
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="employee.position"
                label="Position"
                hide-details="auto"
                outlined
                dark
              />
            </v-col>
          </v-row>
          <v-radio-group hide-details row v-model="employee.company" mandatory>
            <label class="mr-3 white--text">Company:</label>
            <v-radio
              dark
              color="primary"
              label="JLR Contractors"
              value="JLR Contractors"
            />
            <v-radio
              dark
              color="primary"
              label="JLR Woodwork"
              value="JLR Woodwork"
            />
          </v-radio-group>
          <v-radio-group hide-details row v-model="employee.team" mandatory>
            <label class="mr-3 white--text">Team:</label>
            <v-radio
              dark
              v-for="item in teams"
              :key="item.i"
              color="primary"
              :label="item"
              :value="item"
            />
          </v-radio-group>
          <v-btn
            color="primary"
            :disabled="!valid"
            width="100%"
            class="mt-3"
            outlined
            dark
            :loading="addLoad"
            rounded
            @click="
              addLoad = true;
              updateRecords();
            "
          >
            Submit
          </v-btn>
        </v-form>
        <v-snackbar v-model="snackbar">
          {{ snackText }}

          <template v-slot:action="{ attrs }">
            <v-btn
              color="primary"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>
    <v-row class="mt-16 pt-10" justify="center">
      <v-col cols="12" md="8" class="">
        <v-btn
          outlined
          rounded
          color="primary"
          @click="startAdd()"
          class="mb-3"
        >
          Add Employee
        </v-btn>
        <v-data-table
          :headers="headers"
          :items="employees"
          item-key="id"
          sort-by="order"
          :items-per-page="15"
          class="elevation-1 mb-10"
          no-data-text="No Current Employees Added"
        >
          <template v-slot:item.photo="{ item }">
            <v-avatar v-if="item" class="profile" color="grey" size="120" tile>
              <v-img :src="item.image.temp" />
            </v-avatar>
          </template>

          <template v-slot:item.employee="{ item }">
            {{ item.name }}
          </template>

          <template v-slot:item.order="{ item }">
            <v-select
              v-model="item.order"
              :items="listLength"
              hide-details="auto"
              outlined
              @change="updateOrder(item)"
            />
          </template>

          <template v-slot:item.actions="{ item }">
            <div class="d-flex">
              <v-btn icon @click="add(item)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn @click="remove(item)" icon>
                <v-icon color="red">mdi-delete-outline</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="deleteDialog"
      v-if="selected != null"
    >
      <v-card>
        <v-toolbar color="secondary" dark> Remove Application </v-toolbar>
        <v-card-text>
          <div class="display-1 pt-5">
            {{ selected.name }}
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="deleteDialog = false">Close</v-btn>
          <v-btn text @click="removeApp(selected.id)" color="error">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { employeesCollection, storage } from "../firebase/credentials";
export default {
  data() {
    return {
      employees: [],
      expanded: [],
      deleteDialog: false,
      addLoad: false,
      selected: null,
      addEmployee: false,
      headers: [
        { text: "Photo", value: "photo", width: 250, sortable: false },
        { text: "Employee", value: "employee", sortable: false },
        { text: "Position", value: "position", sortable: false },
        { text: "Company", value: "company", sortable: false },
        { text: "Team", value: "team", sortable: false },
        { text: "Order", value: "order", sortable: false, width: "100" },
        { text: "", value: "actions", width: "25", sortable: false },
      ],
      teams: ["Executive", "Management", "Supervisor"],
      tempEmployee: {
        image: {
          file: null,
          path: null,
          temp: null,
        },
        team: null,
      },
      employee: {
        image: {
          file: null,
          path: null,
          temp: null,
        },
        team: null,
      },
      valid: false,
      snackbar: false,
      snackText: "Success",
    };
  },
  computed: {
    isImage() {
      if (
        this.employee.image.temp != null &&
        this.employee.image.temp != undefined &&
        this.employee.image != null
      )
        return true;
      else return false;
    },
    listLength() {
      const length = this.employees.map((e, i) => i);
      return length;
    },
  },
  methods: {
    startAdd() {
      this.addEmployee = !this.addEmployee;
      this.employee = {
        image: {
          file: null,
          path: null,
          temp: null,
        },
        team: null,
      };
    },
    remove(item) {
      this.selected = item;
      this.deleteDialog = true;
    },
    async removeApp(id) {
      let url = this.employees.filter((e) => e.id == id)[0].image.path;
      if (url && url != "/uploads/employees/temp.png") {
        const ref = storage.ref(url);
        ref
          .delete()
          .then(async () => {
            await employeesCollection.doc(id).delete();
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        await employeesCollection.doc(id).delete();
      }
      this.deleteDialog = false;
      this.addLoad = false;
    },
    add(emp) {
      this.employee = emp;
      this.addEmployee = true;
      window.scrollTo(0, 0);
    },
    onPickFile() {
      this.$refs.image.click();
    },
    onFilePicked(event) {
      if (event.target.files.length == 0)
        this.employee.image = {
          file: null,
          path: null,
          temp: null,
        };
      Array.from(event.target.files).forEach((file) => {
        let fileName = file.name;
        if (fileName.lastIndexOf(".") <= 0) {
          return alert("Please add a valid image.");
        }

        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
          this.employee.image = {
            temp: fileReader.result,
            file: file,
            path: `/uploads/employees/${file.name}`,
          };
        });
        fileReader.readAsDataURL(file);
      });
    },
    async updateOrder(employee) {
      await employeesCollection.doc(employee.id).update({
        order: employee.order,
      });
    },
    async updateRecords() {
      try {
        if (
          this.employee.image != null &&
          this.employee.image.file != undefined
        ) {
          await storage
            .ref(this.employee.image.path)
            .put(this.employee.image.file);
        }
      } catch (err) {
        console.log(err);
      }

      if (this.valid)
        try {
          let data = {
            name: this.employee.name,
            position: this.employee.position,
            team: this.employee.team,
            company: this.employee.company,
            image:
              this.employee.image.path != undefined
                ? this.employee.image.path
                : "/uploads/employees/temp.png",
            order: this.employee.order || this.employees.length - 1,
          };
          let id;
          if (this.employee.id != null) {
            await employeesCollection
              .doc(this.employee.id)
              .update(data)
              .then(() => {
                this.snackText = "Employee Updated";
                this.snackbar = true;
                this.addEmployee = false;
                this.addLoad = false;
                this.employee = {
                  image: {
                    file: null,
                    path: null,
                    temp: null,
                  },
                  team: null,
                };
              });
          } else {
            await employeesCollection.add(data).then(function (docRef) {
              id = docRef.id;
            });
            this.snackText = "Employee Added";
            this.snackbar = true;
            this.addEmployee = false;
            this.employee = id;
            this.addLoad = false;
            this.employee = {
              image: {
                file: null,
                path: null,
                temp: null,
              },
              team: null,
            };
          }
        } catch (error) {
          console.log(error);
        }
    },
  },
  async mounted() {
    let result = await employeesCollection;
    result.onSnapshot((a) => {
      a.docChanges().forEach(async (p) => {
        if (p.type === "added") {
          let img;
          if (p.doc.data().image) {
            img = await storage
              .ref()
              .child(p.doc.data().image)
              .getDownloadURL();
          }
          let obj = p.doc.data();
          obj.id = p.doc.id;
          (obj.image = {
            path: p.doc.data().image,
            temp: img,
          }),
            this.employees.push(obj);
        } else if (p.type === "modified") {
          let id = p.doc.id;
          let index = this.employees.map((obj) => obj.id).indexOf(id);
          this.employees[index].domain = p.doc.data().domain;
        } else if (p.type === "removed") {
          this.employees.splice(p.doc.data().order, 1);
        }
      });
    });
  },
};
</script>

<style lang="scss" scoped></style>
